import * as React from "react"

import "./subscribe.css"

const Subscribe = ({}) => (
  <div className="subscribe" id="mc_embed_signup">
    <form
      action="https://transfusionontario.us14.list-manage.com/subscribe/post?u=d83dcc6407dd979e501cb0e36&amp;id=f58c83d985"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      novalidate
    >
      <h2>Subscribe to receive status updates</h2>
      <div className="form-container">
        <input
          placeholder="Email"
          type="email"
          name="EMAIL"
          className="email"
          id="mce-EMAIL"
        ></input>{" "}
        <div id="mce-responses" className="clear">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: "none" }}
          ></div>
          <div
            className="response"
            id="mce-success-response"
            style={{ display: "none" }}
          ></div>
        </div>
        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups  */}
        <div
          style={{ position: "absolute", left: "-5000px", ariaHidden: "true" }}
        >
          <input
            type="text"
            name="b_d83dcc6407dd979e501cb0e36_f58c83d985"
            tabindex="-1"
            value=""
          ></input>
        </div>
        <input
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="button"
        ></input>
      </div>
    </form>
  </div>
)

export default Subscribe
